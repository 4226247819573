import { ref, reactive } from "vue";
import c3api from "../c3api";
import { useRouter } from "vue-router/auto";
import { useToast } from "vue-toastification";
import { unwrapReactive } from "../utils/unwrapReactive";

export default function useTenants() {
	const tenantsLoading = ref(false);
	const tenants = ref(null);
	const toast = useToast();
	const defaultLocalState = reactive({});
	const router = useRouter();

	const fetchTenants = async (localState = defaultLocalState) => {
		tenantsLoading.value = true;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get("/tenants", { params });
			tenants.value = response.data;
		} catch (error) {
			console.log(error);
			toast.error(error.response.data.data.join(". "));
		}
		tenantsLoading.value = false;
	};

	const fetchTenant = async (id, localState = defaultLocalState) => {
		tenantsLoading.value = true;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/tenants/${id}`, { params });
			tenants.value = response.data;
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
			router.push("/404");
		}
		tenantsLoading.value = false;
	};

	const createTenant = async (localState = defaultLocalState) => {
		tenantsLoading.value = true;
		let params = unwrapReactive(localState);
		try {
			const response = await c3api.post("/tenants", params);
			toast.success("Tenant created successfully!");
			router.push("/admin/tenants");
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		}
		tenantsLoading.value = false;
	};

	const deleteTenant = async (user) => {
		tenantsLoading.value = true;
		const confirmation = confirm(
			`Are you sure you want to delete user ${user.name}?`
		);
		if (confirmation) {
			try {
				const response = await c3api.delete(`/tenants/${user.id}`);
				toast.success(`Tenant ${user.name} deleted successfully`);
				return response.data;
			} catch (error) {
				console.error(error);
				toast.error(error.response.data.data.join(". "));
			}
		}
		tenantsLoading.value = false;
	};

	function updatePage(newPage) {
		router.push({ query: { page: newPage } });
	}

	return {
		tenants,
		fetchTenants,
		fetchTenant,
		createTenant,
		deleteTenant,
		updatePage,
	};
}
